/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useEffect, useCallback } from 'react'
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const HRCostCalculatorPage = () => {
  // Software Purchase Parameters
  const [purchaseParams, setPurchaseParams] = useState({
    numEmployees: 100,
    costPerEmployee: 15, // Monthly cost per employee
    upfrontCost: 25000, // Setup, implementation, training
    yearsOfUse: 5,
    annualMaintenancePercent: 20 // % of annual subscription
  })

  // In-house Development Parameters
  const [inHouseParams, setInHouseParams] = useState({
    complexity: "medium",
    numDevelopers: 5,
    monthlyDevCost: 12000, // Per developer
    developmentMonths: 9,
    trainingCost: 50000,
    infrastructureCost: 25000,
    annualMaintenancePercent: 20 // % of development cost
  })

  // Common Parameters
  const [softwareType, setSoftwareType] = useState("performance")
  const [showResults, setShowResults] = useState(false)

  // Results State
  const [calculatedCosts, setCalculatedCosts] = useState({
    purchase: {},
    inHouse: {}
  })

  // Effect to update development months based on complexity
  useEffect(() => {
    const months = {
      low: 6,
      medium: 9,
      high: 12
    }
    setInHouseParams(prev => ({
      ...prev,
      developmentMonths: months[inHouseParams.complexity] || 9
    }))
  }, [inHouseParams.complexity])

  // Enhanced cost calculation logic
  const calculateCosts = useCallback(() => {
    // Purchase Solution Calculations
    const {
      numEmployees,
      costPerEmployee,
      upfrontCost,
      yearsOfUse,
      annualMaintenancePercent
    } = purchaseParams

    const monthlySubscription = numEmployees * costPerEmployee
    const annualSubscription = monthlySubscription * 12
    const totalSubscriptionCost = annualSubscription * yearsOfUse
    
    // Maintenance starts from year 2
    const annualMaintenance = (annualSubscription * annualMaintenancePercent / 100)
    const totalMaintenanceCost = annualMaintenance * (yearsOfUse - 1)

    // Volume discounts for larger organizations
    let volumeDiscount = 1
    if (numEmployees > 1000) volumeDiscount = 0.8
    else if (numEmployees > 500) volumeDiscount = 0.9
    
    const adjustedSubscriptionCost = totalSubscriptionCost * volumeDiscount
    const purchaseTotalCost = upfrontCost + adjustedSubscriptionCost + totalMaintenanceCost

    // In-house Development Calculations
    const {
      numDevelopers,
      monthlyDevCost,
      developmentMonths,
      trainingCost,
      infrastructureCost
    } = inHouseParams

    // Initial development cost
    const developmentCost = numDevelopers * monthlyDevCost * developmentMonths

    // Ongoing costs
    const annualOperatingCost = (developmentCost * inHouseParams.annualMaintenancePercent / 100)
    const totalOperatingCost = annualOperatingCost * (yearsOfUse - 1) // Maintenance starts year 2

    // Complexity factors for infrastructure and training
    const complexityFactors = {
      low: 0.8,
      medium: 1,
      high: 1.3
    }
    const complexityFactor = complexityFactors[inHouseParams.complexity] || 1

    const adjustedInfrastructure = infrastructureCost * complexityFactor
    const adjustedTraining = trainingCost * complexityFactor

    const inHouseTotalCost = developmentCost + totalOperatingCost + adjustedInfrastructure + adjustedTraining

    // Set calculated results
    setCalculatedCosts({
      purchase: {
        upfrontCost,
        monthlyPerEmployee: costPerEmployee,
        annualSubscription,
        totalSubscription: adjustedSubscriptionCost,
        maintenanceCost: totalMaintenanceCost,
        total: purchaseTotalCost,
        perEmployee: purchaseTotalCost / numEmployees,
        perEmployeePerMonth: purchaseTotalCost / numEmployees / (yearsOfUse * 12),
        implementationMonths: 3 // Typical vendor implementation time
      },
      inHouse: {
        developmentCost,
        operatingCost: totalOperatingCost,
        infrastructureCost: adjustedInfrastructure,
        trainingCost: adjustedTraining,
        total: inHouseTotalCost,
        perEmployee: inHouseTotalCost / numEmployees,
        perEmployeePerMonth: inHouseTotalCost / numEmployees / (yearsOfUse * 12),
        implementationMonths: developmentMonths,
        teamSize: numDevelopers
      }
    })
  }, [purchaseParams, inHouseParams])

  // Update calculations when parameters change
  useEffect(() => {
    calculateCosts()
  }, [calculateCosts])

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    calculateCosts()
    setShowResults(true)
    // Scroll to results
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        const resultsElement = document.getElementById('results-section')
        if (resultsElement) {
          resultsElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 100)
    }
  }

  // Handle parameter updates
  const updatePurchaseParams = (field, value) => {
    setPurchaseParams(prev => ({
      ...prev,
      [field]: Number(value)
    }))
  }

  const updateInHouseParams = (field, value) => {
    setInHouseParams(prev => ({
      ...prev,
      [field]: field === 'complexity' ? value : Number(value)
    }))
  }

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount)
  }
  
  return (
    <Layout className="tools-page">
      <SEO 
        title="HR Software Cost Calculator"
        description="Compare the total cost of ownership between building in-house HR software and buying off-the-shelf solutions. Interactive calculator for businesses of all sizes."
        keywords={[
          "HR software cost calculator",
          "build vs buy HR software",
          "HR technology cost comparison",
          "HRIS ROI calculator",
          "HR software total cost of ownership",
          "HR software implementation costs"
        ]}
        image="/assets/hr-cost-calculator-tool.png"
      />
      <div className="wrapper">
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>HR Software Cost Calculator</h1>
        
        <div sx={{
          maxWidth: '800px',
          margin: '0 auto',
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
        }}>
          {/* Introduction and explanation section */}
          <div sx={{ mb: 4, pb: 4, borderBottom: '1px solid', borderColor: 'rgba(0,0,0,0.1)' }}>
            <h2 sx={{ fontSize: ['1.5rem', '1.65rem', '1.75rem'], mb: 3, color: 'primary' }}>
              Understanding the Build vs. Buy Decision
            </h2>
            
            <p sx={{ fontSize: ['1rem', '1rem', '1rem'], mb: 3 }}>
              One of the most significant decisions when implementing HR technology is whether to build custom software in-house or purchase an existing solution from a vendor. This calculator helps you compare the total cost of ownership over time between these two approaches.
            </p>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'], 
              gap: 4, 
              mb: 3,
              bg: 'primaryMuted',
              p: 3,
              borderRadius: '8px'
            }}>
              <div>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], mb: 2, color: 'text' }}>
                  Building In-House Includes:
                </h3>
                <ul sx={{ pl: 3 }}>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Initial development costs</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Ongoing maintenance expenses</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Staff training costs</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Opportunity costs of longer implementation</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Full control over features and roadmap</li>
                </ul>
              </div>
              
              <div>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], mb: 2, color: 'text' }}>
                  Buying a Solution Includes:
                </h3>
                <ul sx={{ pl: 3 }}>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Setup and implementation fees</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Subscription/licensing costs</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Customization expenses</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Training and support costs</li>
                  <li sx={{ mb: 1, fontSize: '1rem' }}>Faster implementation timeline</li>
                </ul>
              </div>
            </div>
            
            <p sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
              <strong>How to use this calculator:</strong> Adjust the parameters below to match your organization's specifics. Basic parameters provide a quick estimate, while advanced options allow for more detailed customization. Once you've input your data, click "Calculate Costs" to see a comprehensive comparison.
            </p>
          </div>
          
          <p sx={{ fontSize: '1rem', mb: 3 }}>
            Compare the costs of building your HR software in-house versus purchasing an off-the-shelf solution.
            Adjust the parameters below to get a customized comparison for your organization.
          </p>
          
          <p sx={{ fontSize: '1rem', mb: 4, fontStyle: 'italic', bg: 'rgba(0,0,0,0.03)', p: 2, borderRadius: '4px' }}>
            This calculator accounts for economies of scale, meaning larger organizations may find in-house development more cost-effective over time.
            High-complexity systems with extensive customization requirements also tend to favor the in-house approach. Smaller organizations 
            typically benefit from vendor solutions due to lower upfront costs and faster implementation.
          </p>
          
          <form onSubmit={handleSubmit} sx={{ mb: 4 }}>
            {/* Software Type Selection */}
            <div sx={{ mb: 4, pb: 3, borderBottom: '1px solid', borderColor: 'rgba(0,0,0,0.1)' }}>
              <label htmlFor="softwareType" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                HR Software Type
              </label>
              <select 
                id="softwareType"
                value={softwareType}
                onChange={(e) => setSoftwareType(e.target.value)}
                sx={{
                  width: '100%',
                  p: [1, 2, 2],
                  fontSize: ['16px', '16px', 'inherit'],
                  border: '1px solid',
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '4px',
                  appearance: 'none',
                  backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 0.7rem top 50%',
                  backgroundSize: '0.65rem auto',
                  paddingRight: '1.75rem'
                }}
              >
                <option value="performance">Performance Management Software</option>
                <option value="hris">HRIS (Human Resource Information System)</option>
                <option value="ats">Applicant Tracking System (ATS)</option>
                <option value="payroll">Payroll Management System</option>
                <option value="learning">Learning Management System (LMS)</option>
                <option value="benefits">Benefits Administration System</option>
                <option value="time">Time & Attendance Management</option>
              </select>
              
              {/* Software Type Description */}
              <div sx={{ mt: 3, p: 3, bg: 'primaryMuted', borderRadius: '8px', fontSize: ['0.9rem', '0.95rem', '1rem'] }}>
                {softwareType === "performance" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Performance Management Software</h4>
                    <p>Includes features for goal setting, performance reviews, 360° feedback, continuous feedback, and development planning. Consider integration needs with HRIS and complexity of review cycles.</p>
                  </div>
                )}
                {softwareType === "hris" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Human Resource Information System</h4>
                    <p>Core HR system for employee data management, organizational structure, reporting, and basic HR processes. Consider data migration complexity and integration requirements with other systems.</p>
                  </div>
                )}
                {softwareType === "ats" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Applicant Tracking System</h4>
                    <p>Manages recruitment process, job postings, candidate tracking, and hiring workflows. Consider integration with job boards, career sites, and background check providers.</p>
                  </div>
                )}
                {softwareType === "payroll" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Payroll Management System</h4>
                    <p>Handles payroll processing, tax calculations, benefits deductions, and compliance. Consider local tax regulations, multi-state requirements, and integration with time tracking.</p>
                  </div>
                )}
                {softwareType === "learning" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Learning Management System</h4>
                    <p>Manages training content, course delivery, learning paths, and certifications. Consider content creation tools, mobile accessibility, and integration with performance management.</p>
                  </div>
                )}
                {softwareType === "benefits" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Benefits Administration System</h4>
                    <p>Manages employee benefits enrollment, health insurance, retirement plans, and leave management. Consider integration with insurance providers and compliance requirements.</p>
                  </div>
                )}
                {softwareType === "time" && (
                  <div>
                    <h4 sx={{ mb: 2, fontSize: ['1rem', '1.1rem', '1.1rem'] }}>Time & Attendance Management</h4>
                    <p>Tracks employee time, attendance, scheduling, and leave management. Consider integration with payroll, mobile access needs, and hardware requirements for time clocks.</p>
                  </div>
                )}
              </div>
            </div>
            
            {/* Software Purchase Section */}
            <div sx={{ 
              mb: 4, 
              p: 4, 
              bg: 'white', 
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>
                Software Purchase Parameters
              </h3>
              
              <div sx={{ display: 'grid', gap: 3 }}>
                <div>
                  <label htmlFor="numEmployees" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Number of Employees
                  </label>
                  <input 
                    id="numEmployees"
                    type="number" 
                    min="10" 
                    max="10000"
                    value={purchaseParams.numEmployees}
                    onChange={(e) => updatePurchaseParams('numEmployees', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                </div>
                
                <div>
                  <label htmlFor="costPerEmployee" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Monthly Cost per Employee ($)
                  </label>
                  <input 
                    id="costPerEmployee"
                    type="number" 
                    min="5" 
                    max="100"
                    value={purchaseParams.costPerEmployee}
                    onChange={(e) => updatePurchaseParams('costPerEmployee', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                  <p sx={{ mt: 1, fontSize: '0.9rem', color: 'gray' }}>
                    Typical range: $5-8 basic, $10-15 mid-tier, $18-25+ enterprise
                  </p>
                </div>
                
                <div>
                  <label htmlFor="upfrontCost" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Upfront Cost ($)
                  </label>
                  <input 
                    id="upfrontCost"
                    type="number" 
                    min="0" 
                    max="1000000"
                    step="1000"
                    value={purchaseParams.upfrontCost}
                    onChange={(e) => updatePurchaseParams('upfrontCost', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                  <p sx={{ mt: 1, fontSize: '0.9rem', color: 'gray' }}>
                    Includes setup, implementation, and initial training
                  </p>
                </div>
                
                <div>
                  <label htmlFor="yearsOfUse" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Years of Use
                  </label>
                  <input 
                    id="yearsOfUse"
                    type="range" 
                    min="1" 
                    max="10"
                    value={purchaseParams.yearsOfUse}
                    onChange={(e) => updatePurchaseParams('yearsOfUse', e.target.value)}
                    sx={{ 
                      width: '100%',
                      height: '2rem'
                    }}
                  />
                  <div sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <span>1 year</span>
                    <span>{purchaseParams.yearsOfUse} years</span>
                    <span>10 years</span>
                  </div>
                </div>
              </div>
            </div>

            {/* In-house Development Section */}
            <div sx={{ 
              mb: 4, 
              p: 4, 
              bg: 'white', 
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>
                In-house Development Parameters
              </h3>
              
              <div sx={{ display: 'grid', gap: 3 }}>
                <div>
                  <label htmlFor="complexity" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    System Complexity
                  </label>
                  <select 
                    id="complexity"
                    value={inHouseParams.complexity}
                    onChange={(e) => updateInHouseParams('complexity', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="low">Low - Basic HR functions (6 months)</option>
                    <option value="medium">Medium - Standard HR suite (9 months)</option>
                    <option value="high">High - Advanced HR & analytics (12 months)</option>
                  </select>
                </div>
                
                <div>
                  <label htmlFor="numDevelopers" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Number of Developers Required
                  </label>
                  <input 
                    id="numDevelopers"
                    type="number" 
                    min="1" 
                    max="50"
                    value={inHouseParams.numDevelopers}
                    onChange={(e) => updateInHouseParams('numDevelopers', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                </div>
                
                <div>
                  <label htmlFor="monthlyDevCost" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Monthly Cost per Developer ($)
                  </label>
                  <input 
                    id="monthlyDevCost"
                    type="number" 
                    min="3000" 
                    max="25000"
                    step="1000"
                    value={inHouseParams.monthlyDevCost}
                    onChange={(e) => updateInHouseParams('monthlyDevCost', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                  <p sx={{ mt: 1, fontSize: '0.9rem', color: 'gray' }}>
                    Include salary, benefits, and overhead costs
                  </p>
                </div>
                
                <div>
                  <label htmlFor="trainingCost" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Training Budget ($)
                  </label>
                  <input 
                    id="trainingCost"
                    type="number" 
                    min="0" 
                    max="500000"
                    step="5000"
                    value={inHouseParams.trainingCost}
                    onChange={(e) => updateInHouseParams('trainingCost', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                </div>
                
                <div>
                  <label htmlFor="infrastructureCost" sx={{ display: 'block', mb: 2, fontWeight: 600 }}>
                    Infrastructure Cost ($)
                  </label>
                  <input 
                    id="infrastructureCost"
                    type="number" 
                    min="0" 
                    max="500000"
                    step="5000"
                    value={inHouseParams.infrastructureCost}
                    onChange={(e) => updateInHouseParams('infrastructureCost', e.target.value)}
                    sx={{
                      width: '100%',
                      p: 2,
                      fontSize: '16px',
                      border: '1px solid',
                      borderColor: 'rgba(0,0,0,0.2)',
                      borderRadius: '4px'
                    }}
                  />
                  <p sx={{ mt: 1, fontSize: '0.9rem', color: 'gray' }}>
                    Includes servers, tools, testing environments, etc.
                  </p>
                </div>
              </div>
            </div>

            <button 
              type="submit"
              sx={{
                display: 'inline-block',
                width: ['100%', 'auto'],
                px: 4,
                py: 2,
                bg: 'primary',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                fontWeight: 600,
                fontSize: '1rem',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bg: 'highlight',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}
            >
              {showResults ? 'Recalculate Costs' : 'Calculate Costs'}
            </button>
          </form>
          
          {showResults && (
            <div sx={{ mt: 5 }} id="results-section">
              <h2 sx={{ 
                textAlign: 'center', 
                fontSize: '1.75rem',
                mb: 4,
                pb: 2,
                borderBottom: '1px solid rgba(0,0,0,0.1)'
              }}>
                Cost Comparison Results
              </h2>
              
              <div sx={{ 
                display: 'grid', 
                gridTemplateColumns: ['1fr', null, '1fr 1fr'],
                gap: 4,
                mb: 4
              }}>
                {/* Purchase Solution Results */}
                <div sx={{ 
                  p: 4, 
                  bg: 'white',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                  <h3 sx={{ mb: 3, color: 'primary', fontSize: '1.25rem' }}>Purchase Solution</h3>
                  
                  <div sx={{ mb: 4, bg: 'rgba(0,0,0,0.03)', p: 3, borderRadius: '8px' }}>
                    <h4 sx={{ mb: 2, fontSize: '1.1rem' }}>One-Time Costs</h4>
                    <div sx={{ display: 'grid', gap: 2 }}>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Setup & Implementation:</span>
                        <span>{formatCurrency(calculatedCosts.purchase.upfrontCost)}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ mb: 4, bg: 'rgba(0,0,0,0.03)', p: 3, borderRadius: '8px' }}>
                    <h4 sx={{ mb: 2, fontSize: '1.1rem' }}>Annual Costs</h4>
                    <div sx={{ display: 'grid', gap: 2 }}>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Subscription:</span>
                        <span>{formatCurrency(calculatedCosts.purchase.annualSubscription)}</span>
                      </div>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Maintenance & Support:</span>
                        <span>{formatCurrency(calculatedCosts.purchase.maintenanceCost / (purchaseParams.yearsOfUse - 1))}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ 
                    mt: 3,
                    pt: 3,
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    fontSize: '1.1rem',
                    fontWeight: 'bold'
                  }}>
                    <div sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <span>Total Cost ({purchaseParams.yearsOfUse} years):</span>
                      <span>{formatCurrency(calculatedCosts.purchase.total)}</span>
                    </div>
                    <div sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem' }}>
                      <span>Cost per employee/month:</span>
                      <span>{formatCurrency(calculatedCosts.purchase.perEmployeePerMonth)}</span>
                    </div>
                  </div>
                </div>

                {/* In-house Development Results */}
                <div sx={{ 
                  p: 4, 
                  bg: 'white',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                  <h3 sx={{ mb: 3, color: 'primary', fontSize: '1.25rem' }}>Build In-House</h3>
                  
                  <div sx={{ mb: 4, bg: 'rgba(0,0,0,0.03)', p: 3, borderRadius: '8px' }}>
                    <h4 sx={{ mb: 2, fontSize: '1.1rem' }}>Initial Costs</h4>
                    <div sx={{ display: 'grid', gap: 2 }}>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Development ({inHouseParams.developmentMonths} months):</span>
                        <span>{formatCurrency(calculatedCosts.inHouse.developmentCost)}</span>
                      </div>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Infrastructure Setup:</span>
                        <span>{formatCurrency(calculatedCosts.inHouse.infrastructureCost)}</span>
                      </div>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Training:</span>
                        <span>{formatCurrency(calculatedCosts.inHouse.trainingCost)}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ mb: 4, bg: 'rgba(0,0,0,0.03)', p: 3, borderRadius: '8px' }}>
                    <h4 sx={{ mb: 2, fontSize: '1.1rem' }}>Ongoing Costs</h4>
                    <div sx={{ display: 'grid', gap: 2 }}>
                      <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Annual Maintenance:</span>
                        <span>{formatCurrency(calculatedCosts.inHouse.operatingCost / (purchaseParams.yearsOfUse - 1))}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ 
                    mt: 3,
                    pt: 3,
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    fontSize: '1.1rem',
                    fontWeight: 'bold'
                  }}>
                    <div sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <span>Total Cost ({purchaseParams.yearsOfUse} years):</span>
                      <span>{formatCurrency(calculatedCosts.inHouse.total)}</span>
                    </div>
                    <div sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9rem' }}>
                      <span>Cost per employee/month:</span>
                      <span>{formatCurrency(calculatedCosts.inHouse.perEmployeePerMonth)}</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Comparison Summary */}
              <div sx={{ 
                p: 4, 
                bg: 'primaryMuted',
                borderRadius: '8px',
                textAlign: 'center'
              }}>
                <h3 sx={{ mb: 3, fontSize: '1.25rem' }}>
                  {calculatedCosts.inHouse.total > calculatedCosts.purchase.total 
                    ? `Buying is more economical by ${formatCurrency(calculatedCosts.inHouse.total - calculatedCosts.purchase.total)}`
                    : `Building in-house is more economical by ${formatCurrency(calculatedCosts.purchase.total - calculatedCosts.inHouse.total)}`
                  }
                </h3>
                
                <div sx={{ bg: 'white', p: 3, borderRadius: '4px' }}>
                  <h4 sx={{ mb: 2 }}>Key Considerations</h4>
                  <ul sx={{ textAlign: 'left', pl: 4 }}>
                    <li sx={{ mb: 1 }}>Development Timeline: {calculatedCosts.inHouse.implementationMonths} months vs {calculatedCosts.purchase.implementationMonths} months</li>
                    <li sx={{ mb: 1 }}>Team Size Required: {calculatedCosts.inHouse.teamSize} developers</li>
                    <li sx={{ mb: 1 }}>Monthly Cost Per Employee: {formatCurrency(calculatedCosts.inHouse.perEmployeePerMonth)} vs {formatCurrency(calculatedCosts.purchase.perEmployeePerMonth)}</li>
                    <li sx={{ mb: 1 }}>Initial Investment: {formatCurrency(calculatedCosts.inHouse.developmentCost + calculatedCosts.inHouse.infrastructureCost)} vs {formatCurrency(calculatedCosts.purchase.upfrontCost)}</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          
          {/* FAQs Section */}
          <div sx={{ mt: 5, borderTop: '1px solid', borderColor: 'rgba(0,0,0,0.1)', pt: 4 }}>
            <h2 sx={{ textAlign: 'center', mb: 4, fontSize: ['1.5rem', '1.65rem', '1.75rem'] }}>
              Frequently Asked Questions
            </h2>
            
            <div sx={{ maxWidth: '700px', mx: 'auto' }}>
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How accurate is this HR software cost calculator?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  This calculator provides estimates based on industry averages and typical cost structures. Actual costs can vary significantly based on specific vendors, your exact requirements, negotiated rates, and other factors. We recommend using these calculations as a starting point for your decision-making process, not as definitive figures.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  When is building HR software in-house a better option?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Building in-house is typically better when:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Your organization has highly unique or specialized requirements that off-the-shelf solutions can't satisfy</li>
                  <li>You have a large organization where economies of scale make the per-employee cost more favorable over time</li>
                  <li>You need complete control over the development roadmap and features</li>
                  <li>You have an established IT team with HR software expertise</li>
                </ul>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  When is buying an off-the-shelf HR solution better?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Purchasing an existing solution is generally better when:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>You have standard HR processes that don't require extensive customization</li>
                  <li>You're a small to medium-sized business with limited IT resources</li>
                  <li>You need a solution implemented quickly</li>
                  <li>You want predictable ongoing costs</li>
                  <li>You prefer regular updates and support from a dedicated vendor</li>
                </ul>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What costs does this calculator include?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  For in-house development, we include: initial development costs, ongoing maintenance, training costs, and opportunity costs of longer implementation. For vendor solutions, we include: setup fees, subscription costs, customization expenses, training costs, and ongoing support and upgrades. The calculator adjusts these costs based on organization size, complexity level, and other factors.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What costs might not be included in these calculations?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Some potential costs not explicitly included are: data migration costs, integration with existing systems, internal IT support costs, hardware requirements, compliance and security audits, and potential downtime during implementation. These can vary significantly between organizations and should be considered separately.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How does system complexity affect costs?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Higher complexity systems require larger development teams, longer development times, more expensive maintenance, and typically higher per-employee costs for vendor solutions. Our calculator scales these factors based on your selection of low, medium, or high complexity. A high-complexity system might include advanced analytics, AI-driven features, or extensive integration with other business systems.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How do economies of scale affect the build vs. buy decision?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  For larger organizations, the fixed costs of in-house development (like initial programming) get spread across more employees, reducing the per-employee cost. Vendor solutions typically charge per employee, although they often offer volume discounts. This calculator accounts for these economies of scale, which is why in-house solutions may become more cost-effective as employee count increases.
                </p>
              </div>
              
              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What factors beyond cost should I consider?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  While cost is important, also consider: implementation timeline, alignment with your HR processes, integration capabilities, user experience, mobile accessibility, reporting capabilities, security and compliance features, vendor reputation and stability, and your internal capacity to support and maintain the system long-term.
                </p>
              </div>

              {/* Software-Specific FAQs */}
              <h3 sx={{ fontSize: ['1.5rem', '1.65rem', '1.75rem'], fontWeight: 600, mb: 3, mt: 5, color: 'primary', textAlign: 'center' }}>
                Software-Specific FAQs
              </h3>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What are the unique considerations for Performance Management Software?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  For performance management systems, consider:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Customization needs for your review process</li>
                  <li>Integration requirements with HRIS and compensation systems</li>
                  <li>Complexity of your performance cycles</li>
                  <li>Need for 360-degree feedback capabilities</li>
                  <li>Mobile accessibility for continuous feedback</li>
                  <li>Analytics and reporting requirements</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Custom solutions might be preferred if you have unique performance methodologies.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What should I know about HRIS implementation costs?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  HRIS costs are significantly impacted by:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Data migration complexity from legacy systems</li>
                  <li>Number of integrations required with other systems (payroll, benefits, etc.)</li>
                  <li>Custom workflow requirements</li>
                  <li>Employee self-service features</li>
                  <li>Compliance requirements for different regions</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Large organizations with complex workflows often find custom HRIS solutions more cost-effective long-term.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How do ATS costs vary by organization size?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  ATS costs typically scale with:
                </p>
                <ul sx={{ pl: 4, mt: 2, listStyleType: 'disc' }}>
                  <li sx={{ mb: 2 }}>Volume of hiring (not just employee count)</li>
                  <li sx={{ mb: 2 }}>Number of integration points with job boards and assessment tools</li>
                  <li sx={{ mb: 2 }}>Complexity of hiring workflows</li>
                  <li sx={{ mb: 2 }}>Career site customization needs</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 2 }}>
                  Vendor solutions often work well for standard recruiting processes, while custom solutions might be better for unique hiring approaches or high-volume recruiting.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What drives costs in Payroll Management Systems?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Key cost factors include:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Multi-state/country tax compliance requirements</li>
                  <li>Integration with time tracking and benefits systems</li>
                  <li>Custom payment rules and calculations</li>
                  <li>Direct deposit and payment processing fees</li>
                  <li>Year-end tax reporting needs</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Vendor solutions are often more cost-effective due to built-in compliance updates and tax table maintenance.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What affects Learning Management System (LMS) costs?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  LMS costs are influenced by:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Content creation and hosting needs</li>
                  <li>Number of concurrent users</li>
                  <li>Integration with performance management</li>
                  <li>Custom learning path requirements</li>
                  <li>Certification tracking complexity</li>
                  <li>Mobile learning capabilities</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Consider whether you need extensive custom content creation tools or specific compliance training features.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How do Benefits Administration System costs compare?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Consider these factors:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Number and complexity of benefit plans</li>
                  <li>Integration requirements with insurance carriers</li>
                  <li>Open enrollment process complexity</li>
                  <li>Life event management needs</li>
                  <li>Compliance reporting requirements</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Vendor solutions often provide better carrier connectivity and compliance updates, making them more cost-effective for standard benefits programs.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  What impacts Time & Attendance System costs?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Key cost considerations include:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>Time clock hardware requirements</li>
                  <li>Mobile time tracking needs</li>
                  <li>Complex scheduling rules and overtime calculations</li>
                  <li>Integration with payroll systems</li>
                  <li>Geolocation and biometric verification needs</li>
                </ul>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'], mt: 3 }}>
                  Custom solutions might be preferred for unique shift patterns or complex labor rule calculations.
                </p>
              </div>

              <div sx={{ mb: 4 }}>
                <h3 sx={{ fontSize: ['1.25rem', '1.25rem', '1.25rem'], fontWeight: 600, mb: 2, color: 'primary' }}>
                  How do integration costs vary between HR software types?
                </h3>
                <p sx={{ fontSize: ['0.95rem', '1rem', '1rem'] }}>
                  Integration costs vary significantly by software type:
                </p>
                <ul sx={{ 
                  pl: 4, 
                  mt: 2,
                  listStyleType: 'disc',
                  '& li': {
                    fontSize: ['0.95rem', '1rem', '1rem'],
                    mb: 2,
                    lineHeight: '1.5',
                    color: 'text',
                    '&::marker': {
                      fontSize: ['0.95rem', '1rem', '1rem'],
                      color: 'primary'
                    }
                  }
                }}>
                  <li>HRIS typically requires the most integrations and tends to be most expensive</li>
                  <li>Payroll systems need secure, reliable integrations with banks and tax systems</li>
                  <li>ATS integrations with job boards are usually straightforward with vendor solutions</li>
                  <li>Benefits systems require careful testing with carrier systems</li>
                  <li>Time & Attendance systems need robust payroll integration for accuracy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HRCostCalculatorPage 